import React from "react";
import { Link } from "@mui/material";
import { styled } from "@mui/system";

import Button from '@mui/material/Button';
import TwitterIcon from '@mui/icons-material/Twitter';
import ChatIcon from '@mui/icons-material/Chat';

import imageLogo from '../images/logo_rhinos.png';

const SocialLinks = styled('div')({});

const FooterRoot = styled("div")({
  width: "100%",
  padding: 45,
  background: "#000",
  borderTop: "2px solid #000",
  
  textAlign: "center",
  display: "fleX",
  justifyContent: "center",
  alignItems: "center",
});

const LogoContainer = styled('div')({
  flex: '1 1 auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const Logo = styled('img')({
  width: 350,
  height: 350,
});

export default function Footer() {
  return (
    <FooterRoot>

      <br />
      <SocialLinks>
        <Button
          href="https://discord.gg/ekm6be6eXJ"
          target="_blank"
          rel="noreferrer"
        >
          <ChatIcon />
        </Button>
        <Button
          href="https://twitter.com/RudeRhinosClub"
          target="_blank"
          rel="noreferrer"
        >
          <TwitterIcon />
        </Button>
      </SocialLinks>
    </FooterRoot>
  );
  
}
